import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/adobe.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/brainpop.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/musiquest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/parent-avatar.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(marketing)/style-grid.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/teacher-avatar.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/testimonial1.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/testimonial2.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/testimonial3.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@playwright+test@1.49.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@playwright+test@1.49.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
